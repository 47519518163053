<!--
* 创建人：罗兴
* 日 期：
* 描 述：辅导员考核部门评分
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">辅导员考核</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="topf">评分</el-button>
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div v-html="'批次名称'" class="sm_search_title"></div>
          <el-input size="mini" v-model="keyword"></el-input>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 240"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="pcmc" label="批次名称"> </el-table-column>
        <el-table-column prop="kssj" label="开始时间">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.kssj) }}
          </template>
        </el-table-column>
        <el-table-column prop="jssj" label="结束时间">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.jssj) }}
          </template>
        </el-table-column>
        <el-table-column prop="xn" label="学年"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="辅导员考核批次"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-dialog
        title="教职工信息(双击选择)"
        :visible.sync="dialogLSVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5fcolor42">教工号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入教工号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getLSPageList"
            style="margin-left: 10px"
            >搜索</el-button
          >
        </div>
        <el-table
          :data="lsdataPageList"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selLS"
        >
          <el-table-column property="jgh" label="教工号"></el-table-column>
          <el-table-column property="xm" label="姓名"></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleLSCurrentChange"
            :current-page="pagination2.page"
            :page-size="pagination2.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination2.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-scrollbar style="height: 350px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="教工号" label-width="120px" prop="JGH">
                <el-input
                  @focus="showLSSel"
                  v-model="forminfo.JGH"
                  autocomplete="off"
                  maxlength="20"
                  :disabled="this.operation == 0"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="120px" prop="XM">
                <el-input
                  :disabled="true"
                  v-model="forminfo.XM"
                  autocomplete="off"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-for="(item, index) in gzlist"
            :key="index"
            style="height: 40px"
          >
            <el-col :span="18">{{
              index + 1 + '、' + item.gzmc + '(' + item.khyd + ')'
            }}</el-col>
            <el-col :span="6"
              ><el-input
                size="mini"
                v-model="fslist[index]"
                placeholder="请输入内容"
                type="number"
              ></el-input
            ></el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getPCPageList, saveBMPF, deletePC, getListToPF } from '../../api/fdykh'
import { getXNList } from '../../api/system'
import { getLSPageList } from '../../api/jcxx'
import moment from 'moment'
export default {
  data() {
    return {
      dialogFormVisible: false,
      keyValue: '',
      keyword: '',
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      formdata: {},
      operation: 1,
      forminfo: {
        ID: '',
        PCID: '',
        JGH: '',
        XM: '',
        PFRZH: '',
        PFR: '',
        FS: '',
      },
      currdata: {},
      xnList: [],
      gzlist: [],
      fslist: [],
      dialogLSVisible: false,
      pagination2: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      // 表单验证规则对象
      forminfoRules: {},
      selkeyword: '',
      lsdataPageList: [],
    }
  },
  created() {
    getXNList().then((res) => {
      if (res.code === 200) {
        this.xnList = res.data
      }
    })
    this.getDataPageList()
  },
  methods: {
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getPCPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    todelete() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deletePC({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
        this.bm = ''
        this.zy = ''
      })
      this.$refs.formRef.resetFields()
    },
    topf() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getListToPF().then((res) => {
          if (res.code === 200) {
            this.gzlist = res.data
            this.fslist = []
            for (var i = 0; i < res.data.length; i++) {
              this.fslist.push(0)
            }
          }
        })
        this.dialogFormVisible = true
      }
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          var fs = 0
          for (var i = 0; i < this.fslist.length; i++) {
            fs += parseFloat(this.fslist[i])
          }
          this.forminfo.FS = fs
          this.forminfo.PCID = this.keyValue
          saveBMPF(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    showLSSel() {
      this.dialogLSVisible = true
      this.getLSPageList()
    },
    handleLSCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination2.page = val
      this.getLSPageList()
    },
    getLSPageList() {
      getLSPageList({
        queryJson: JSON.stringify({ keyword: this.selkeyword }),
        pagination: JSON.stringify(this.pagination2),
      })
        .then((res) => {
          if (res.code === 200) {
            this.pagination2.page = res.data.page
            this.pagination2.total = res.data.total
            this.pagination2.records = res.data.records
            this.lsdataPageList = res.data.rows
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selLS(row, column, event) {
      this.forminfo.JGH = row.jgh
      this.forminfo.XM = row.xm
      this.dialogLSVisible = false
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
